<!--
 * @Author: Libra
 * @Date: 2021-08-12 10:01:25
 * @LastEditTime: 2024-03-04 15:07:04
 * @LastEditors: Libra
 * @Description: 公开作答
 * @FilePath: /stone-exam-ui/src/views/common/PublicLogin.vue
-->
<template>
  <div class="common-bg login">
    <simple-header :hasRight="false" />
    <el-dialog
      title="国考云隐私保护条例"
      :visible.sync="showPrivateRule"
      width="600px"
      :center="true"
      :modal="false"
      custom-class="private-rule"
    >
      <private-rule></private-rule>
    </el-dialog>
    <GlobalDialog
      :dialogVisible="loginLoading"
      @dialog-cancel="loginLoading = false"
      title="提示"
      :isShowFooter="false"
    >
      <div class="loading-container">
        <div class="loading">
          <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <span>系统登录中，请稍后...</span>
      </div>
    </GlobalDialog>
    <div class="container">
      <div class="left">
        <div class="title">
          {{ shortName ? shortName + userExamName + scenario : `在线考试` }}
        </div>
        <div class="exam-time" v-if="!!time">
          考试时间：{{
            time.examStartAt
              .substring(0, time.examStartAt.length - 3)
              .replace(/-/g, '/')
          }}
          -
          {{
            time.examEndAt
              .substring(0, time.examEndAt.length - 3)
              .replace(/-/g, '/')
          }}
        </div>
        <div class="welcome">欢迎你参加{{ scenario }}！</div>
        <div class="tips">
          <div class="tip">请使用最新版Chrome浏览器进行考试；</div>
          <div class="tip">提前登录系统，检测调试电脑及手机设备；</div>
          <div class="tip">请关闭QQ微信等软件，不要跳出考试页面；</div>
          <div class="tip">注意考试纪律，诚信作答；</div>
          <div class="tip">网络故障中断考试，重新登录继续作答即可。</div>
        </div>
        <div class="left-time" v-show="!!time && resetSec > 0">
          <span class="count-down-title" v-if="second >= 0">
            距离开考还有：</span
          >
          <span
class="count-down"
v-if="day > 0"
            >{{ day }} <span style="font-size: 16px">天</span></span
          >
          <span
class="count-down"
v-else-if="day <= 0 && hour > 0"
            >{{ hour }} <span style="font-size: 16px">小时</span></span
          >
          <span
class="count-down"
v-else-if="hour <= 0 && minute > 0"
            >{{ minute }} <span style="font-size: 16px">分钟</span></span
          >
          <span
class="count-down"
v-else-if="minute <= 0 && second > 0"
            >{{ second }} <span style="font-size: 16px">秒</span></span
          >
        </div>
      </div>
      <div class="right login-box">
        <div class="title">用户登录</div>
        <el-form
          :model="loginForm"
          ref="loginForm"
          :rules="rules"
          class="login-form"
        >
          <el-form-item prop="loginName">
            <el-input
              v-model="loginForm.loginName"
              placeholder="请输入手机号"
            />
          </el-form-item>
          <el-form-item prop="imageCode" class="code">
            <el-input
              v-model="loginForm.imageCode"
              placeholder="请输入验证码"
              @keyup.enter.native="handleLogin"
            />
            <span class="line" />
            <img
              class="code-img"
              :src="codeBase64"
              alt="验证码"
              @click="imageValidationCode"
            />
          </el-form-item>
          <div>
            <el-checkbox v-model="privateRule"></el-checkbox>
            <span
@click="showPrivateRule = true"
class="private-rule-label"
              >国考云隐私保护条例</span
            >
          </div>
          <el-button
type="primary"
class="login-btn"
@click="handleLogin"
            >登录</el-button
          >
          <router-link
            class="check"
            target="_blank"
            v-slot="{ href }"
            custom
            :to="{ name: 'DeviceDetection' }"
          >
            <a :href="href" role="link" class="link">
              <i
class="iconfont iconshexiangtou2"
                ><span class="link-content">设备网络检测</span></i
              >
            </a>
          </router-link>
        </el-form>
      </div>
    </div>
    <simple-footer :shortName="shortName"/>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import PrivateRule from '@/components/privateRule'
import GlobalDialog from '@/components/GlobalDialog'
import { removeToken } from '@/utils/auth'
import Api from '@/api/api'
import store from '@/store'
import { clearAll, setItem } from '@/utils/storage'
import { mapMutations, mapActions } from 'vuex'

export default {
  components: {
    SimpleHeader,
    SimpleFooter,
    GlobalDialog,
    PrivateRule
  },
  data() {
    return {
      loginForm: {
        loginName: '',
        imageCode: '',
        examId: '',
        key: ''
      },
      time: '',
      shortName: '',
      loginLoading: false,
      codeBase64: null,
      examId: '',
      userExamName: '',
      scenario: '在线考试',
      day: null,
      hour: null,
      minute: null,
      second: null,
      resetSec: null,
      showPrivateRule: false,
      privateRule: false,
      rules: {
        loginName: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确格式的手机号' }
        ],
        imageCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    console.log('public login created')
    this.loginForm.examId = this.$route.query.exam || ''
    this.imageValidationCode()
    this.getShortName()
    // 清除Storage
    clearAll()
    removeToken()
    const { shortId, exam } = this.$route.query
    setItem('shortId', shortId)
    setItem('exam', exam)
  },
  methods: {
    ...mapMutations([`setExamInfo`]),
    ...mapActions([`updateExamInfo`]),
    /**
     * info相关
     */
    async examInfo() {
      const res = await Api.examInfo()
      if (res.code === 0) {
        const resData = res.data
        this.setExamInfo(resData)
        this.updateExamInfo(resData)
      }
    },
    setDocumentTitle() {
      document.title = this.shortName
        ? this.shortName + this.scenario + '系统-国考云'
        : '国考云在线考试'
    },
    // 图形验证码
    imageValidationCode: async function() {
      const res = await Api.imageValidationCode()
      this.codeBase64 = res.data.imageCode
      this.loginForm.key = res.data.key
    },
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          if (!this.privateRule) {
            this.$message.error('请先同意国考云隐私保护条例！')
            return
          }
          this.loginLoading = true
          const res = await Api.login(this.loginForm)
          if (res && res.code === 0) {
            store.commit('setToken', res.data.token)
            this.loginLoading = false
            this.$message({
              message: '登录成功',
              type: 'success',
              duration: 3000,
              showClose: true
            })
            await this.examInfo()
            this.$router.push('/basic')
          } else {
            this.loginForm.imageCode = ''
            this.loginLoading = false
            // this.$message.error('登录失败')
          }
          this.imageValidationCode()
        }
      })
    },
    async getTime() {
      if (!this.examId) return
      const res = await Api.getTimeByExamId(this.examId)
      this.time = res.data
      this.resetSec = this.time.secondToStart
      const timer = setInterval(this.examCountDown, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    },
    async getShortName() {
      const shortId = this.$route.query.shortId
      if (!shortId || shortId === 'undefined') return
      const res = await Api.getNameAndLogo({
        examShortId: shortId
      })
      this.shortName = res.data.companyShortName
      this.userExamName = res.data.userExamName
      this.scenario = res.data.scenario
      this.examId = res.data.examUuid
      this.getTime()
      this.setDocumentTitle()
    },
    // 开始倒计时
    examCountDown() {
      this.day = parseInt(this.resetSec / (60 * 60 * 24))
      this.hour = parseInt((this.resetSec / (60 * 60)) % 24)
      this.minute = parseInt((this.resetSec / 60) % 60)
      this.second = parseInt(this.resetSec % 60)
      this.resetSec -= 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/variables.scss';
.login {
  box-sizing: border-box;
  position: relative;
  ::v-deep .private-rule {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    .el-dialog__body {
      height: 600px;
      overflow: auto;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    .loading {
      .lds-default {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-default div {
        position: absolute;
        width: 6px;
        height: 6px;
        background: #ff5f5f;
        border-radius: 50%;
        animation: lds-default 1.2s linear infinite;
      }
      .lds-default div:nth-child(1) {
        animation-delay: 0s;
        top: 37px;
        left: 66px;
      }
      .lds-default div:nth-child(2) {
        animation-delay: -0.1s;
        top: 22px;
        left: 62px;
      }
      .lds-default div:nth-child(3) {
        animation-delay: -0.2s;
        top: 11px;
        left: 52px;
      }
      .lds-default div:nth-child(4) {
        animation-delay: -0.3s;
        top: 7px;
        left: 37px;
      }
      .lds-default div:nth-child(5) {
        animation-delay: -0.4s;
        top: 11px;
        left: 22px;
      }
      .lds-default div:nth-child(6) {
        animation-delay: -0.5s;
        top: 22px;
        left: 11px;
      }
      .lds-default div:nth-child(7) {
        animation-delay: -0.6s;
        top: 37px;
        left: 7px;
      }
      .lds-default div:nth-child(8) {
        animation-delay: -0.7s;
        top: 52px;
        left: 11px;
      }
      .lds-default div:nth-child(9) {
        animation-delay: -0.8s;
        top: 62px;
        left: 22px;
      }
      .lds-default div:nth-child(10) {
        animation-delay: -0.9s;
        top: 66px;
        left: 37px;
      }
      .lds-default div:nth-child(11) {
        animation-delay: -1s;
        top: 62px;
        left: 52px;
      }
      .lds-default div:nth-child(12) {
        animation-delay: -1.1s;
        top: 52px;
        left: 62px;
      }
      @keyframes lds-default {
        0%,
        20%,
        80%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.5);
        }
      }
    }
    span {
      font-size: 20px;
      margin-top: 20px;
      color: rgb(58, 58, 58);
    }
  }
  .container {
    overflow: hidden;
    min-height: calc(100vh - 200px);
    .left {
      float: left;
      background-image: url('~@/assets/images/illustration.png');
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: 300px;
      color: #4d4d4d;
      padding-top: 80px;
      width: 700px;
      box-sizing: border-box;
      padding-bottom: 20px;
      .title {
        color: #333;
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .exam-time {
        font-size: 16px;
        margin-bottom: 80px;
      }
      .welcome {
        font-size: 24px;
        margin-bottom: 44px;
      }
      .tips {
        font-size: 14px;
        line-height: 2.2;
        margin-bottom: 36px;
      }
      .left-time {
        font-size: 18px;
        font-weight: bold;
        .count-down {
          font-size: 20px;
          color: $primary-color;
        }
      }
    }
    .right.login-box {
      float: right;
      margin-top: 40px;
      margin-bottom: 0px;
      width: 480px;
      background-color: #fff;
      border-radius: 4px;
      padding: 45px;
      box-sizing: border-box;
      box-shadow: 0 0 18px #ddd;
      ::v-deep .el-input__inner {
        border: 1px solid #c1c1c1;
      }
      .link {
        display: flex;
        justify-content: center;
        align-items: center;
        .iconshexiangtou2 {
          font-size: 20px;
          &:hover {
            color: #f35a5a;
          }
        }
        .link-content {
          vertical-align: 2px;
          font-size: 16px;
          &:hover {
            color: #f35a5a;
          }
        }
      }
      .title {
        font-size: 22px;
        text-align: center;
        color: $primary-color;
        margin-bottom: 48px;
      }
      .login-form {
        color: #000;
        .code {
          position: relative;
          .code-img {
            width: 108px;
            height: 36px;
            object-fit: contain;
            position: absolute;
            right: 6px;
            top: 6px;
            border-left: 1px solid #ccc;
          }
          ::v-deep .el-input__inner {
            padding-right: 128px;
          }
        }
        .private-rule-label {
          margin-left: 10px;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            color: #f35a5a;
          }
        }

        ::v-deep .el-input__inner {
          height: 48px;
          line-height: 48px;
          border-radius: 0;
          color: #000;
          font-weight: bold;
          font-family: Verdana;
        }
        .login-btn {
          margin-top: 22px;
          width: 100%;
          height: 52px;
          font-size: 20px;
          margin-bottom: 32px;
          background-color: rgb(243, 90, 90);
          border: none;
          &:hover {
            background-color: rgba(243, 90, 90, 0.8);
          }
        }
        .check {
          text-decoration: none;
          display: block;
          color: #8c8c8c;
          font-size: 16px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          .icon-camera {
            vertical-align: bottom;
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
